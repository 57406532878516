@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../font/Lato-Light.bfc37ff1d5543ecd4ec6.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Lato-Regular.9919edff6283018571ad.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    src: url(../font/Lato-Italic.0b4ed00316a7940bfc3f.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../font/Lato-Bold.5dd5aa0269395f0ea927.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../font/Lato-Black.0b51cf32bbc2d8b8d840.ttf) format('truetype');
}

html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #191919    ;
    --heading-primary-color: #000000;
    --bg-primary-color: #191919;
    --link-primary-color: #DD3C45;
    --button-primary-color: #4EEFA4;
    --bg-circle-color: #744148;
    --li-color: #201B1B;
    --li-circle-color: #89C142;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}

body {
    font-size: 14px;
    line-height: 26px;
    font-family: Lato, sans-serif;
    color: #FFFFFF;
    background: #FFFFFF;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    min-width: 320px;
    margin: 0px;
}

h1 {
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    margin: 20px 0;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    color: var(--heading-primary-color);
    margin: 20px 0;
}

h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 50px;
    color: var(--heading-primary-color);
    margin: 20px 0;
}

p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: var(--text-primary-color);
    padding: 20px 0;
}
ul{
    text-align: left;
    font-weight: 500;
}
ul li{
    font-style: normal;
    color: var(--li-color);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin: 20px 0 20px 20px;
}
ul.check, ul.cons {
    list-style: none;
}
ul.check li::before, ul.cons li::before{
    font-weight: bold;
    display: inline-block;
    width: 1.4em;
    margin-left: -1em;
}
ul.check li::before {
    content: "✓";
    color: var(--li-circle-color);
}
ul.cons li::before {
    content: "+";
    color: var(--link-primary-color);
}
.text-center {
    text-align: center;
}
.text-white {
    color: #FFFFFF
}
a.text-white{
    color: #FFFFFF!important;
}
a.text-underline{
    text-decoration: underline;
}

.grid {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}

.col2 {
    grid-template-columns: 1fr 1fr;
}

.col2-1 {
    grid-template-columns: 2fr 1fr;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}

a {
    text-decoration: none;
    color: var(--link-primary-color);
    ;
}
.group-btn{
    display: flex;
}
.btn{
    margin-right: 20px;
}
.btn {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 20px;
    color: var(--text-primary-color);
    display: flex;
    height: 72px;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 35px;
    width: 295px;
    transition: background-color 0.5s ease;
    transition: color 0.3s ease;
}
.btn .txt {
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    margin-left: 24px;
    pointer-events: none;
}
.btn .arrow {
    background-color: var(--link-primary-color);
    background-image: url(../image/arrow-white.png);
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 20px;
    height: 58px;
    margin-right: 8px;
    pointer-events: none;
    width: 60px;
}
.btn:hover {
    background-color: var(--link-primary-color);
    cursor: pointer;
    color: #FFFFFF;
}
/* body */


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 10px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    padding: 0;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-header {
    width: 100%;
    background: var(--bg-primary-color);
    position: relative;

}
.site-logo {
    position: relative;
    z-index: 3;
    padding: 20px 0;
}

 .footer .main-logo {
    position: relative;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    display: inline-block;
    width: 200px;
    height: 30px;
    vertical-align: middle;
}
.site-logo .main-logo{
   font-size: 0px;
    background: url(../image/logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    height: 43px;
    max-width: 100%;
    display: block;
}
.intro-primary {
    width: 100%;
    background: var(--bg-primary-color);
    padding-bottom: 80px;

    position: relative;
}

#blur-circle-right {
    position: absolute;
    width: 20%;
    height: 50%;
    top: 20%;
    right: 0;
    bottom: 0;
    background: var(--bg-circle-color);
    filter: blur(110px);

}
#blur-circle-bottom {
    position: absolute;
    width: 20%;
    height: 35%;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    background: var(--bg-circle-color);
    filter: blur(130px);
}

.intro-primary .title {
    position: relative;
    z-index: 3;
}

.intro-highlight {
    color: #72DBF6;
}

.intro-entry p{
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #E4E5F1;
}
.main-image{
    background-image: url(../image/header-image.webp);
    height: 500px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#street-fashion{
    background-color: var(--bg-primary-color);
}
#street-fashion .inner-box, #luxury-fashion .inner-box{
    padding: 50px 0;
}
.types {
    margin-top: 30px;
    position: relative;
}

#street-fashion .types .type {
    background: #FFFFFF;
    box-shadow: 11px 14px 60px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 40px;
    margin: 30px 0;
}

#street-fashion .types .type:first-child {
    margin-top: 0px;
}
.types .type .type-title {
    background-size: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left;
    display: block;
}
#street-fashion .types .type .type-title h3 {
    position: relative;
    margin-left: 100px;
}
.types .footer {
    padding: 50px 0 0px;
}
.types .footer .images {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}
.types .type img{
    border-radius: 28px;
    max-width: 100%;
}

.types .type .link-group{
    display: flex;
    padding-top: 20px;
}
.types .type .link-group .link{ 
    color: var(--text-primary-color)
}
.types .type .link-group .link a{ 
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: var(--link-primary-color);
    border-bottom-width: 3px;
    border-bottom-style: solid;
    padding-bottom: 5px;
    display: table-cell;
}
.types .type .link-group .link:first-child{
    margin-right: 100px;
}
#street-fashion .type-title.one-icon{
    background-image: url(../image/bobshop.png);
}
#street-fashion .type-title.two-icon{
    background-image: url(../image/digitalax.png);
}
#street-fashion .type-title.three-icon{
    background-image: url(../image/dcentlife.png);
    background-size: contain;
}
#luxury-fashion{
    background-image: url(../image/background-model-1.webp), url(../image/background-model-2.webp);
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;
}
#luxury-fashion .type{
    margin: 50px 0;
}
#luxury-fashion .types .type .type-title {
    background-size: auto;
    font-size: 0;
    height: 50px;
}
#luxury-fashion .type-title.one-icon{
    background-image: url(../image/dolce-gabbana.png);
}
#luxury-fashion .type-title.two-icon{
    background-image: url(../image/rtfkt.png);
}
#luxury-fashion .type-title.three-icon{
    background-image: url(../image/neuno.png);
}
#luxury-fashion .types .type .link-group{
    padding-bottom: 30px;
}
#tools .inner-box{
    background: #FEF9F9;
    border-radius: 30px;
    padding-top: 20px;
}
#tools .types .type{
    padding: 20px 40px;
}
#tools .types .type .type-title{
    font-weight: 900;
    font-size: 36px;
    line-height: 50px;
    color: var(--heading-primary-color);
}

/* F&Q */

/* F&Q */
.faq-wrapper {
    margin-top: 60px;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #EDECEE;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.faq.expanded .faq-title{
    padding-bottom: 20px;
}

.faq-btn {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
}

.faq-btn:hover {
    cursor: pointer;
}

.faq {
    padding: 10px 30px;
    transition: all 0.1s ease-in;
}
.faq .faq-body{
    color: var(--text-primary-color);
}
.faq.collapsed .faq-btn {
    background-image: url(../image/faq-expand.svg);
    filter: invert(45%) sepia(100%) saturate(5238%) hue-rotate(339deg) brightness(93%) contrast(84%);
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded .faq-btn {
    background-image: url(../image/faq-collapse.svg);
    background-size: contain;
}

.faq.expanded {
    background: #FEF9F9;
    border-radius: 15px;
    border: none;
    padding: 10px 30px 30px;    
}

.faq.faq.expanded+.faq {
    border-top: 0px;
}

.faq.expanded .faq-title h3 {
    margin-bottom: 0px;
    font-weight: 900;
}

.faq .faq-title h3 {
    pointer-events: none;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
}

.faq-listing {
    margin-top: 30px;
}

.about-us-wrapper{
    background-color: #0d1c2d;
    border-radius: 20px;
    box-shadow: 11px 14px 60px rgb(46 92 118 / 20%);
    margin-bottom: 38px;
    margin-top: 82px;
    padding: 47px 52px 65px;
}
.about-us-wrapper h2{
    margin-top: 0px;
}

.about-us-wrapper ul{
        list-style: none;
        margin-left: 36px;
}

.about-us-wrapper ul li {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 28px;
}
.about-us-wrapper  ul li:before {
    color: #6165d9;
    content: "\2022";
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    margin-left: -3em;
    width: 3em;
}
/* Footer */

.footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #828C98;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 20px 0 30px;
}
.footer-inner a{
    color: #828C98;
}
/* read more ↓↓↓ */

.m-readmore-btn .img,
.m-readmore-btn .txt,
.read-more .img,
.read-more .txt {
    pointer-events: none;
}

.desktop-slide-down {
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 0;
    overflow: hidden;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media (min-width: 1300px) {
    .container {
        min-width: 1350px;
    }
}

@media only screen and (min-width: 868px) and (max-width: 1080px) {
    .container{
        padding: 0 20px;
    }
    .col2-1, .col1-2, .col2 {
        grid-template-columns: 1fr;
    }
    .main-image{
        display: none;
    }
    .types .type img{
        width: 100%;
        max-width: 200px;
    }
    #street-fashion .types .type .type-title h3{
        max-width: 500px;
    }
}

@media only screen and (max-width: 867px) {
    
    .mobile-detector {
        display: none;
    }

    .m-hidden {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    .container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    h1{
        font-weight: 900;
        font-size: 34px;
        line-height: 41px;
    }
    h2{
        font-weight: 900;
        font-size: 26px;
        line-height: 62px;
    }
    h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }
    p{
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
    }
    .col2-1, .col1-2, .col2 {
        grid-template-columns: 1fr;
    }
    .main-image{
        display: none;
    }
    .intro-entry{
        margin-bottom: 0px;
    }
    .logo {
        padding: 0px;
    }
    .intro-entry{
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
    }
    .group-btn {
        display: block;
    }
    .btn{
        width: 100%;
        margin-right: 0px;
        max-width: 350px;
    }
    .inner-img img{
        width: 100%;
    }
    #street-fashion .inner-box{
        padding: 1px 0;
    }
    #street-fashion .types .type, #tools .types .type{
        padding: 20px;
    }
    .types .type .link-group .link:first-child {
        margin-right: 50px;
    }
   .types .type .type-title h3 {
        max-width: 160px;
    }
    .types .m-readmore-btn-wrapper,
    .types .read-more-wrapper {
        width: 100%;
    }

    .types .m-readmore-btn,
    .types .read-more {
        color: var(--link-primary-color);
        height: 60px;
        width: 100%;
        border-radius: 20px;
        border: 1px solid var(--link-primary-color);
        background-color: transparent;
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
    }

    .types .m-readmore-btn:active,
    .types .read-more:active {
        background-color: #fff0e6;
    }

    .types .m-readmore-btn .img {
        background-image: url(../image/arrow.png);
        width: 16px;
        height: 18px;
        margin-left: 18px;
        filter: invert(45%) sepia(100%) saturate(5238%) hue-rotate(339deg) brightness(93%) contrast(84%);
    }
    .types .footer .images {
        padding-left: 24px;
        position: relative;
        height: 215px;
    }
    
    .types .footer img {
        width: 274px;
        margin-left: 0px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .types .read-more-wrapper {
        padding-top: 5px;
    }
    
    .types .read-more {
        color: var(--link-primary-color);
        height: 50px;
        width: 140px;
        font-weight: 900;
        font-size: 18px;
        line-height: 50px;
        border: 0;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .types .read-more .img {
        background-image: url(../image/arrow.png);
        background-repeat: no-repeat;
        width: 16px;
        height: 18px;
        margin-left: 18px;
    }
    
    .types .read-more:hover {
        cursor: pointer;
    }
    
    .types .footer img:nth-of-type(1) {
        margin-left: 52px;
    }
    .types .footer img:nth-of-type(3) {
        margin-right: 55px;
    }
     /* Mobile read more slide area ↓↓↓ */

     .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }

    /* Mobile slider ↓↓↓ */

    .m-slider-nav {
        margin: 20px auto 0px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .m-slider-nav ul li {
        list-style-type: none;
        width: 7px;
        height: 7px;
        background-color: #DEDDE2;
        border-radius: 12px;
    }

    .m-slider-nav ul li.active {
        background-color: var(--link-primary-color);
    }

    .m-slider-img {
        height: 0;
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    .m-slider-img.active {
        opacity: 1;
        height: auto;
    }

    .conclution-box{
        padding: 15px;
        margin: 0 8px;
        top: 50px;
    }
    #luxury-fashion .types .footer{
        padding-top: 80px;
    }
    #tools .types .footer {
        padding: 100px 0 0px;
    }
    #tools #sing .footer .m-slider-nav{
        margin-top: 70px;
    }
    #tools .inner-box{
        padding: 20px 0 0 0;
    }
    .faq-wrapper {
        margin-top: 30px;
    }
    .faq {
        padding: 5px 15px;
    }
    .faq .faq-title{
        padding: 15px 0;
    }
    .faq .faq-title h3{
        font-size: 18px;
        max-width: 270px;
        margin: 0px;
    }
    .faq.expanded {
        border-radius: 15px;
        padding: 5px 15px;
    }
    .about-us-wrapper{
        padding-left: 15px;
        padding-right: 15px;
    }
    .about-us-wrapper h2{
        margin-left: 20px;
        margin-top: 0px;
    }
    .about-us-wrapper ul li{
        margin-left: 20px;
    }
    .footer-inner{
        justify-content: center;
        padding-bottom: 30px;
        display: grid;
        text-align: center;
    }
}
